<template>
    <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
        :vid="vid"
    >
        <b-form-group
            :description="description"
            :invalid-feedback="errors[0]"
            :label="required ? name + ' * ' : name"
        >
            <b-form-input
                :debounce="debounce"
                :dir="dir"
                :formatter="formatter"
                :lazy-formatter="lazyFormatter"
                :placeholder="placeholder"
                :plaintext="plaintext"
                :readonly="readonly"
                :state="errors.length > 0 ? false : null"
                :type="type"
                :value="value"
                @input="emitInput"
            />
        </b-form-group>
    </validation-provider>
</template>

<script>
import { DefaultInputProps } from './defaultProps'
import { BFormGroup, BFormInput } from "bootstrap-vue";

export default {
    name: "CustomInput",

    components: {
        BFormInput,
        BFormGroup
    },

    props: {
        ...DefaultInputProps
    },

    methods: {
        emitInput(value) {
            this.$emit('input', value)
        },
    }
}
</script>
