import Vue from 'vue'
// import { getToken } from '@/auth/utils'
// import useJwt from "@/auth/jwt/useJwt";

// const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)

const convertToFormData = (params) => {
    let form_data = new FormData()
    // form_data.set(params)
    for (let key in params) {
        form_data.append(key, params[key]);
    }

    return form_data
}

export const ApiCore = {
    query(resource, params, headers = {}) {
        return Vue.prototype.$http.get(resource, {
            params
        }, headers).catch(error => {
            throw new Error(`ApiCore ${error}`)
        })
    },

    get(resource, id, headers = {}) {
        return Vue.prototype.$http.get(`${resource}/${id}`, { headers }).catch(error => {
            throw new Error(`ApiCore ${error}`)
        })
    },

    post(resource, params, headers = {}) {
        return Vue.prototype.$http.post(`${resource}`, params, headers)
    },

    update(resource, id, params, headers = {}) {
        return Vue.prototype.$http.post(`${resource}/${id}`, params, headers)
    },

    put(resource, params, headers = {}) {
        return Vue.prototype.$http.put(`${resource}`, params, headers)
    },

    delete(resource, id) {
        return Vue.prototype.$http.delete(`${resource}/${id}`).catch(error => {
            throw new Error(`ApiCore ${error}`)
        })
    }
}

export const authService = {
    login(params) {
        return ApiCore.post('login', params)
    },
    logout() {
        return ApiCore.post('logout')
    },
    refreshToken(params) {
        return ApiCore.post('auth/refresh-token', params)
    }
}

export const userService = {
    current() {
        return ApiCore.query('users/current')
    },
    create(params) {
        return ApiCore.post('users', params)
    },
    update(params, id) {
        return ApiCore.put(`users/${id}`, params)
    },
    delete(id, params) {
        return ApiCore.delete(`users/${id}`, params)
    },
    restore(id) {
        return ApiCore.post(`users/restore/${id}`)
    },
    fetch(params) {
        return ApiCore.query('users', params)
    },
    find(id) {
        return ApiCore.get(`users`, id)
    },
    changeStatus(id, status) {
        return ApiCore.post(`users/change-status/${id}`, {
            status: status
        })
    },
    permissionsList() {
        return ApiCore.query('users/permissions')
    },
    rolesList() {
        return ApiCore.query('users/roles')
    }
}

export const categoryService = {
    fetch(params) {
        return ApiCore.query('categories', params)
    },
    find(id) {
        return ApiCore.get(`categories`, id)
    },
    create(params) {
        return ApiCore.post(`categories`, params)
    },
    edit(params, id) {
        return ApiCore.put(`categories/${id}`, params)
    },
    delete(id) {
        return ApiCore.delete(`categories`, id)
    },
}

export const genreService = {
    fetch(params) {
        return ApiCore.query('genres', params)
    },
    create(params) {
        return ApiCore.post(`genres`, params)
    },
    edit(params, id) {
        return ApiCore.put(`genres/${id}`, params)
    },
    delete(id) {
        return ApiCore.delete(`genres`, id)
    },
}

export const redirectService = {
    fetch(params) {
        return ApiCore.query('redirects', params)
    },
    create(params) {
        return ApiCore.post(`redirects`, params)
    },
    edit(params, id) {
        return ApiCore.put(`redirects/${id}`, params)
    },
    delete(id) {
        return ApiCore.delete(`redirects`, id)
    },
}

export const commentService = {
    create(params) {
        return ApiCore.post(`comments`, params)
    },
    fetch(params) {
        return ApiCore.query('comments', params)
    },
    edit(params, id) {
        return ApiCore.put(`comments/${id}`, params)
    }
}

export const actorService = {
    fetch(params) {
        return ApiCore.query('actors', params)
    },
    create(params) {
        return ApiCore.post(`actors`, params)
    },
    edit(params, id) {
        return ApiCore.put(`actors/${id}`, params)
    },
    delete(id) {
        return ApiCore.delete(`actors`, id)
    },
}

export const postService = {
    fetch(params) {
        return ApiCore.query('posts', params)
    },
    create(params) {
        return ApiCore.post(`posts`, params)
    },
    find(id) {
        return ApiCore.get(`posts`, id)
    },
    edit(params, id) {
        return ApiCore.put(`posts/${id}`, params)
    },
    delete(id) {
        return ApiCore.delete(`posts`, id)
    },
    forceDelete(id) {
        return ApiCore.delete(`posts`, id)
    },
    restore(id) {
        return ApiCore.post(`posts/${id}`)
    },
}
