export default [
    {
        path: '/actors',
        name: 'actors',
        component: () => import('@/views/actor/Actors'),
        meta: {
            pageTitle: 'بازیگران',
            breadcrumb: [
                {
                    text: 'بازیگران',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/actors/:id/edit',
        name: 'edit-actor',
        component: () => import('@/views/actor/EditActor'),
        meta: {
            pageTitle: 'ویرایش بازیگر',
            breadcrumb: [
                {
                    text: 'بازیگران',
                    to: '/actors'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/actors/create',
        name: 'create-actor',
        component: () => import('@/views/actor/CreateActor'),
        meta: {
            pageTitle: 'بازیگر جدید',
            breadcrumb: [
                {
                    text: 'بازیگران',
                    to: '/actors'
                },
                {
                    text: 'جدید',
                    active: true,
                },
            ],
        },
    },
]