export default [
    {
        path: '/redirects',
        name: 'redirects',
        component: () => import('@/views/redirect/Redirects'),
        meta: {
            pageTitle: 'ریدایرکت',
            breadcrumb: [
                {
                    text: 'ریدایرکت',
                    active: true,
                },
            ],
            // action: 'redirect-view'
        }
    },
    {
        path: '/redirects/:id/edit',
        name: 'edit-redirect',
        component: () => import('@/views/redirect/EditRedirect'),
        meta: {
            pageTitle: 'ویرایش ریدایرکت',
            breadcrumb: [
                {
                    text: 'ریدایرکت',
                    to: '/redirects'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
            // action: 'redirect-edit'
        },
    },
    {
        path: '/redirects/create',
        name: 'create-redirect',
        component: () => import('@/views/redirect/CreateRedirect'),
        meta: {
            pageTitle: 'ریدایرکت جدید',
            // action: 'redirect-create',
            breadcrumb: [
                {
                    text: 'ریدایرکت',
                    to: '/redirects'
                },
                {
                    text: 'جدید',
                    active: true,
                },
            ],
        },
    },
]