import { postService } from '@/libs/api'
import { groupByArrayOfObject, makeSelectable } from '@/common/arrays'

const initialStates = {
    status: 'LOADING',
    post: {},
    postsList: [],
    pagination: {
        per_page: 15,
        current_page: 1,
        last_page: 1,
    },
    params: {
        page: 1
    }
}

const mutations = {
    loading(state) {
        state.status = 'LOADING'
    },
    setPostsList(state, posts) {
        state.status = 'SET_LIST'
        state.postsList = posts
    },
    setPagination(state, pagination) {
        state.status = 'SET_PAGINATION'
        state.pagination = Object.assign({}, state.pagination, pagination)
    },
    setParams(state, params) {
        state.status = 'SET_PARAMS'
        state.params = Object.assign({}, state.params, params)
    },
    createPost(state, post) {
        state.status = 'CREATE'
        state.postsList[post.id] = post
    },
    findPost(state, post) {
        state.status = 'FIND'
        state.post = post
    },
    updatePost(state, post) {
        state.status = 'UPDATE'
        state.postsList[post.id] = Object.assign({}, state.postsList[post.id], post)
    },
    deletePost(state, id) {
        state.status = 'DELETE_FROM_LIST'
        delete state.postsList[id]
        state.pagination.total--
    },
    error(state) {
        state.status = 'ERROR'
    }
}

const getters = {
    status: state => state.status,
    params: state => state.params,
    posts: state => state.postsList,
    post: state => state.post,
    pagination: state => state.pagination,
}

const actions = {
    fetch({ commit, state }, params = {}) {
        params = Object.assign({}, state.params, params)

        commit('loading')

        return postService.fetch(params).then(response => {
            let result = response.data
            const posts = groupByArrayOfObject(result.data, 'id')

            commit('setPostsList', posts)
            commit('setPagination', {
                per_page: result.per_page,
                current_page: result.current_page,
                last_page: result.last_page,
                total: result.total,
            })
            commit('setParams', {
                page: result.current_page,
            })
        })
    },

    fetchDeleted(module, params = {}) {
        params = Object.assign({}, params, {
            deleted_at: 1,
        })

        return actions.fetch(module, params)
    },

    fetchNotDeleted(module, params = {}) {
        params = Object.assign({}, params, {
            deleted_at: 0,
        })

        return actions.fetch(module, params)
    },

    create({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            postService.create(params)
                .then(response => {
                    // let result = response.data

                    // commit('createPost', result.data)
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    find({ commit }, id = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            postService.find(id)
                .then(response => {
                    let result = response.data.data
                    result.genres = makeSelectable(result.genres, 'id', 'title')
                    result.actors = makeSelectable(result.actors, 'id', 'name')
                    // result.categories = result.categories.map(category => category.id)
                    result.categories = makeSelectable(result.categories, 'id', 'name')
                    result.meta_keywords = result.meta_keywords ? result.meta_keywords.split(',') : []
                    result.compatible = result.compatible ? result.compatible.split(',') : []

                    commit('findPost', result)
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    edit({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            postService.edit(params, params.id)
                .then(() => {
                    commit('updatePost', params)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    delete({ commit }, id) {
        commit('loading')
        return new Promise((resolve, reject) => {
            postService.delete(id)
                .then(() => {
                    commit('deletePost', id)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error)
                })
        })
    },

    restore({ commit }, id) {
        commit('loading')
        return new Promise((resolve, reject) => {
            postService.restore(id)
                .then(() => {
                    commit('deletePost', id)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}