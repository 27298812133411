import { actor, category, comment, genre, post, redirect, user } from "./routes";

export default [
    {
        path: '/',
        redirect: 'dashboard',
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Home'),
        meta: {
            pageTitle: 'داشبورد',
        },
    },
    ...category,
    ...comment,
    ...genre,
    ...post,
    ...redirect,
    ...actor,
    ...user,
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/file-manager',
        name: 'file-manager',
        component: () => import('@/views/fileManager/FileManagerPage'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/file-manager/fm-button',
        name: 'fm-button',
        component: () => import('@/views/fileManager/FmButton'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]