<template>
    <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
        :vid="vid"
    >
        <b-form-group
            :description="description"
            :invalid-feedback="errors[0]"
            :label="required ? name + ' * ' : name"
        >
            <b-form-select
                :disabled="disabled"
                :multiple="multiple"
                :options="options"
                :size="size"
                :text-field="textField"
                :value-field="valueField"
                :value="value"
                :state="errors.length > 0 ? false : null"
                @input="emitInput"
            >
                <template v-for="(_, slot) in $slots">
                    <template :slot="slot">
                        <slot :name="slot" />
                    </template>
                </template>

            </b-form-select>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { DefaultSelectProps } from "./defaultProps";
import { BFormGroup, BFormSelect } from "bootstrap-vue";

export default {
    name: "CustomSelect",

    components: {
        BFormGroup,
        BFormSelect,
    },

    props: {
        ...DefaultSelectProps
    },

    methods: {
        emitInput(value) {
            this.$emit('input', value)
        },
    },

}
</script>
