import { categoryService } from '@/libs/api'
import { groupByArrayOfObject } from '@/common/arrays'

const initialStates = {
    status: 'LOADING',
    categoriesList: [],
    pagination: {
        per_page: 15,
        current_page: 1,
        last_page: 1,
    },
    params: {
        page: 1
    }
}

const mutations = {
    loading(state) {
        state.status = 'LOADING'
    },
    setCategoriesList(state, categories) {
        state.status = 'SET_LIST'
        state.categoriesList = categories
    },
    setPagination(state, pagination) {
        state.status = 'SET_PAGINATION'
        state.pagination = Object.assign({}, state.pagination, pagination)
    },
    setParams(state, params) {
        state.status = 'SET_PARAMS'
        state.params = Object.assign({}, state.params, params)
    },
    createCategory(state, category) {
        state.status = 'CREATE'
        state.categoriesList[category.id] = category
    },
    updateCategory(state, category) {
        state.status = 'UPDATE'
        state.categoriesList[category.id] = Object.assign({}, state.categoriesList[category.id], category)
    },
    deleteCategory(state, id) {
        state.status = 'DELETE_FROM_LIST'
        delete state.categoriesList[id]
    },
    error(state) {
        state.status = 'ERROR'
    }
}

const getters = {
    status: state => state.status,
    params: state => state.params,
    categories: state => state.categoriesList,
    pagination: state => state.pagination,
    categoryById: state => id => state.categoriesList[id],
    selectableCategories: (state, getters) => {
        return Object.keys(getters.categories).map((id) => {
            return {
                value: getters.categories[id].id,
                label: getters.categories[id].name,
            }
        })
    }
}

const actions = {
    fetch({ commit, state }, params = {}) {
        params = Object.assign({}, state.params, params)

        commit('loading')

        return categoryService.fetch(params).then(response => {
            let result = response.data
            const categories = groupByArrayOfObject(result.data, 'id')

            commit('setCategoriesList', categories)
            commit('setPagination', {
                per_page: result.per_page,
                current_page: result.current_page,
                last_page: result.last_page,
                total: result.total,
            })
            commit('setParams', {
                page: result.current_page,
            })
        })
    },

    create({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            categoryService.create(params)
                .then(response => {
                    let result = response.data

                    commit('createCategory', result.data)
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    find({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            categoryService.find(params)
                .then(response => {
                    let result = response.data
                    commit('updateCategory', result.data)
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    edit({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            categoryService.edit(params, params.id)
                .then(() => {
                    commit('updateCategory', params)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    delete({ commit }, id) {
        commit('loading')
        return new Promise((resolve, reject) => {
            categoryService.delete(id)
                .then(() => {
                    commit('deleteCategory', id)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}