export default {
    install(Vue, store) {
        Vue.prototype.$hasRole = role => {
            let current = store.getters['user/current']

            return current && current.role && current.role.some(r => role.includes(r))
        }
        Vue.prototype.$hasPermit = permission => {
            let current = store.getters['user/current']

            return current && current.permission && current.permission.some(r => permission.includes(r))
        }

        Vue.prototype.$permission = (role, action, subject) => {
            return Vue.prototype.$hasRole(role) && Vue.prototype.$ability.can(action, subject)
        }

        Vue.prototype.$currentUser = () => {
            return store.getters['user/current']
        }

        Vue.prototype.$redirect = url => {
            window.location.replace(url)
        }

        Vue.prototype.$stripTags = string => {
            return string.replace(/<\/?[^>]+>/ig, "");
        }
    }
}