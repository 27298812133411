import { commentService, postService } from '@/libs/api'
import { groupByArrayOfObject } from '@/common/arrays'

const initialStates = {
    status: 'LOADING',
    commentsList: [],
    pagination: {
        per_page: 15,
        current_page: 1,
        last_page: 1,
    },
    params: {
        page: 1
    }
}

const mutations = {
    loading(state) {
        state.status = 'LOADING'
    },
    setCommentsList(state, comments) {
        state.status = 'SET_LIST'
        state.commentsList = comments
    },
    setPagination(state, pagination) {
        state.status = 'SET_PAGINATION'
        state.pagination = Object.assign({}, state.pagination, pagination)
    },
    setParams(state, params) {
        state.status = 'SET_PARAMS'
        state.params = Object.assign({}, state.params, params)
    },
    updateComment(state, comment) {
        state.status = 'UPDATE'
        state.commentsList[comment.id] = Object.assign({}, state.commentsList[comment.id], comment)
    },
    createComment(state) {
        state.status = 'CREATED'
    },
    error(state) {
        state.status = 'ERROR'
    }
}

const getters = {
    status: state => state.status,
    params: state => state.params,
    comments: state => state.commentsList,
    pagination: state => state.pagination,
    commentById: state => id => state.commentsList[id],
}

const actions = {
    create({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            commentService.create(params)
                .then(response => {
                    commit('createComment')
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    fetch({ commit, state }, params = {}) {
        params = Object.assign({}, state.params, params)

        commit('loading')

        return commentService.fetch(params).then(response => {
            let result = response.data
            const comments = groupByArrayOfObject(result.data, 'id')

            commit('setCommentsList', comments)
            commit('setPagination', {
                per_page: result.per_page,
                current_page: result.current_page,
                last_page: result.last_page,
                total: result.total,
            })
            commit('setParams', {
                page: result.current_page,
            })
        })
    },
    edit({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            commentService.edit(params, params.id)
                .then(() => {
                    commit('updateComment', params)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}