import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import App from './App.vue'
import i18n from '@/libs/i18n'
import '@/libs/axios'
import helpers from '@/libs/helpers'

// Global Components
import './global-components'

// 3rd party plugins
// import '@/libs/portal-vue'
// import '@/libs/toastification'
import '@/libs/sweet-alerts'

import '@/libs/vee-validate'
import '@/libs/acl'

Vue.use(helpers, store)
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
new Vue({
  router,
  store,
  ability: store.getters.ability,
  i18n,
  render: h => h(App),
}).$mount('#app')
