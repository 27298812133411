<template xmlns:b-form-group="http://www.w3.org/1999/html">
    <div>
        <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
            :vid="vid"
        >
            <b-form-group
                :description="description"
                :invalid-feedback="errors[0]"
                :label="required ? name + ' * ' : name"
            >
                <b-form-textarea
                    :formatter="formatter"
                    :lazy-formatter="lazyFormatter"
                    :max-rows="maxRows"
                    :placeholder="placeholder"
                    :plaintext="plaintext"
                    :rows="row"
                    :dir="dir"
                    :size="size"
                    :state="errors.length > 0 ? false : null"
                    :value="value"
                    @input="emitInput"
                />
            </b-form-group>
        </validation-provider>
    </div>
</template>

<script>
import { DefaultTextAreaProps } from './defaultProps'
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
    name: "CustomTextArea",

    components: {
        BFormTextarea,
        BFormGroup
    },

    props: {
        ...DefaultTextAreaProps
    },

    methods: {
        emitInput(value) {
            this.$emit('input', value)
        },
    }
}
</script>
