import { redirectService } from '@/libs/api'
import { groupByArrayOfObject } from '@/common/arrays'

const initialStates = {
    status: 'LOADING',
    redirectsList: [],
    pagination: {
        per_page: 15,
        current_page: 1,
        last_page: 1,
    },
    params: {
        page: 1
    }
}

const mutations = {
    loading(state) {
        state.status = 'LOADING'
    },
    setRedirectsList(state, redirects) {
        state.status = 'SET_LIST'
        state.redirectsList = redirects
    },
    setPagination(state, pagination) {
        state.status = 'SET_PAGINATION'
        state.pagination = Object.assign({}, state.pagination, pagination)
    },
    setParams(state, params) {
        state.status = 'SET_PARAMS'
        state.params = Object.assign({}, state.params, params)
    },
    createRedirect(state, redirect) {
        state.status = 'CREATE'
        state.redirectsList[redirect.id] = redirect
    },
    updateRedirect(state, redirect) {
        state.status = 'UPDATE'
        state.redirectsList[redirect.id] = Object.assign({}, state.redirectsList[redirect.id], redirect)
    },
    deleteRedirect(state, id) {
        state.status = 'DELETE_FROM_LIST'
        delete state.redirectsList[id]
    },
    error(state) {
        state.status = 'ERROR'
    }
}

const getters = {
    status: state => state.status,
    params: state => state.params,
    redirects: state => state.redirectsList,
    pagination: state => state.pagination,
    redirectById: state => id => state.redirectsList[id]
}

const actions = {
    fetch({ commit, state }, params = {}) {
        params = Object.assign({}, state.params, params)

        commit('loading')

        return redirectService.fetch(params).then(response => {
            let result = response.data
            const redirects = groupByArrayOfObject(result.data, 'id')

            commit('setRedirectsList', redirects)
            commit('setPagination', {
                per_page: result.per_page,
                current_page: result.current_page,
                last_page: result.last_page,
                total: result.total,
            })
            commit('setParams', {
                page: result.current_page,
            })
        })
    },

    create({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            redirectService.create(params)
                .then(response => {
                    let result = response.data

                    commit('createRedirect', result.data)
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    edit({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            redirectService.edit(params, params.id)
                .then(() => {
                    commit('updateRedirect', params)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    delete({ commit }, id) {
        commit('loading')
        return new Promise((resolve, reject) => {
            redirectService.delete(id)
                .then(() => {
                    commit('deleteRedirect', id)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}