<template>
    <b-collapse :visible="visible">
        <b-card no-body>
            <b-card-body>
                <slot
                    :filters="filterFields"
                    name="form"
                />
            </b-card-body>

            <b-card-footer>
                <b-button
                    class="mr-2"
                    variant="primary"
                    @click="search"
                >
                    {{ $t('filter') }}
                </b-button>

                <b-button
                    variant="secondary"
                    @click="clearSearch"
                >
                    {{ $t('clear') }}
                </b-button>
            </b-card-footer>
        </b-card>
    </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BCardFooter } from "bootstrap-vue";

export default {
    name: "CustomFilter",

    components: {
        BCardFooter
    },

    props: {
        visible: {
            default() {
                return false
            },
            type: Boolean
        },
        filters: {
            required: true,
            type: Object,
        }
    },

    data() {
        return {
            localFilters: {}
        }
    },

    computed: {
        ...mapGetters('filter', [
            'filterFields'
        ]),
    },

    created() {
        this.localFilters = JSON.parse(JSON.stringify(this.filters))
        this.setFilterMe(this.filters)
    },

    methods: {
        ...mapActions('filter', [
            'setFilterMe'
        ]),
        search() {
            this.$emit('onSubmit', this.filterFields)
        },
        clearSearch() {
            this.setFilterMe(this.localFilters)

            this.$emit('onClear')
            this.$emit('onSubmit', this.filterFields)
        }
    }
}
</script>
