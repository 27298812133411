export const groupByArrayOfObject = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
        result[currentValue[key]] = currentValue

        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
    }, {}) // empty object is the initial value for result object
}

export const getFirstOfArray = (arr) => {
    return arr[Object.keys(arr)[0]]
}

export const makeSelectable = (arr, value, label) => {
    const grouped = groupByArrayOfObject(arr, value)
    return Object.keys(grouped).map((key) => {
        return {
            value: parseInt(key),
            label: grouped[key][label],
        }
    })
}