import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import {
    BBadge,
    BButtonGroup,
    BCard,
    BCardBody,
    BCardText,
    BCol,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    ButtonPlugin,
    FormPlugin,
    FormTagsPlugin,
} from "bootstrap-vue"
import customInput from "@/layouts/components/form/CustomInput";
import customSelect from "@/layouts/components/form/CustomSelect";
import customTextArea from "@/layouts/components/form/CustomTextArea";
import CustomFilter from "@/layouts/components/CustomFilter";

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('b-card', BCard)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-text', BCardText)
Vue.component('b-table', BTable)
Vue.component('b-badge', BBadge)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-overlay', BOverlay)
Vue.component('b-spinner', BSpinner)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-pagination', BPagination)
Vue.component('b-collapse', BCollapse)
Vue.component('customInput', customInput)
Vue.component('customSelect', customSelect)
Vue.component('customTextArea', customTextArea)
Vue.component('customFilter', CustomFilter)

Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormTagsPlugin)
