const initialStates = {
    filter: {},
}

const mutations = {
    set(state, params) {
        state.filter = JSON.parse(JSON.stringify(params))
    },
    push(state, obj) {
        state.filter = Object.assign({}, state.filter, obj)
    }
}

const getters = {
    filterFields: state => state.filter,
}

const actions = {
    setFilterMe({commit}, params) {
        commit('set', params)
    },
    pushAttribute({commit}, obj) {
        commit('push', obj)
    }
}


export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}