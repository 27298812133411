export default [
    {
        path: '/genres',
        name: 'genres',
        component: () => import('@/views/genre/Genres'),
        meta: {
            pageTitle: 'ژانر',
            breadcrumb: [
                {
                    text: 'ژانر',
                    active: true,
                },
            ],
            action: 'genre-view'
        }
    },
    {
        path: '/genres/:id/edit',
        name: 'edit-genre',
        component: () => import('@/views/genre/EditGenre'),
        meta: {
            pageTitle: 'ویرایش ژانر',
            breadcrumb: [
                {
                    text: 'ژانر',
                    to: '/genres'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
            action: 'genre-edit'
        },
    },
    {
        path: '/genres/create',
        name: 'create-genre',
        component: () => import('@/views/genre/CreateGenre'),
        meta: {
            pageTitle: 'ژانر جدید',
            action: 'genre-create',
            breadcrumb: [
                {
                    text: 'ژانر',
                    to: '/genres'
                },
                {
                    text: 'جدید',
                    active: true,
                },
            ],
        },
    },
]