export default [
    {
        path: '/category',
        name: 'category',
        component: () => import('@/views/category/Categories'),
        meta: {
            pageTitle: 'دسته بندی',
            breadcrumb: [
                {
                    text: 'دسته بندی',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/category/:id/edit',
        name: 'edit-category',
        component: () => import('@/views/category/EditCategory'),
        meta: {
            pageTitle: 'ویرایش دسته بندی',
            breadcrumb: [
                {
                    text: 'دسته بندی',
                    to: '/category'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/category/create',
        name: 'create-category',
        component: () => import('@/views/category/CreateCategory'),
        meta: {
            pageTitle: 'دسته بندی جدید',
            breadcrumb: [
                {
                    text: 'دسته بندی',
                    to: '/category'
                },
                {
                    text: 'جدید',
                    active: true,
                },
            ],
        },
    },
]