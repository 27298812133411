import { authService } from '@/libs/api';
import axios from "@/libs/axios";

const initialStates = {
    accessToken: localStorage.getItem('accessToken') || '',
    user: JSON.parse(localStorage.getItem('userData')) || {},
    status: 's',
    // exp: localStorage.getItem('expToken') ||  ''
}

const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, data) {
        state.status = 'success'
        state.accessToken = data ? data.accessToken : ''
        // state.exp    = data ? data.exp : ''
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = 'logout'
        state.accessToken = ''
    },
}

const getters = {
    isLoggedIn: state => !!state.accessToken,
    status: state => state.status,
    user: state => state.userData,
}

const actions = {
    login({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('auth_request')

            authService.login(params)
                .then(response => {
                        let data = response.data
                        const user = data.data
                        const accessToken = user.token
                        localStorage.setItem('accessToken', accessToken)
                        // const ability = rootGetters.ability
                        // ability.update(user.rules)

                        localStorage.setItem('userData', JSON.stringify(user))
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken

                        commit('auth_success', data)

                        resolve(data)
                    },
                    error => {
                        commit('auth_error')

                        localStorage.removeItem('accessToken')
                        localStorage.removeItem('userData')

                        reject(error.response)
                    }
                )
        })
    },
    logout({ commit }) {
        commit('logout')
        return new Promise((resolve) => {
            authService.logout()
                .then(() => {
                        localStorage.removeItem('accessToken')

                        delete axios.defaults.headers.common['Authorization']
                        location.reload()
                        resolve()
                    }
                )
        })
    }
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}