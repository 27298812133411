import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./route";
import store from '@/store'
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: routes,
})

router.beforeEach((to, _, next) => {
    const beforeEachAction = () => {
        const isLoggedIn = isUserLoggedIn()

        // Redirect to login if not logged in
        if (!isLoggedIn && to.name !== 'login') {
            return next({ name: 'login' })
        }

        if (!canNavigate(to)) {
            // If logged in => not authorized
            return next({ name: 'not-authorized' })
        }

        // Redirect if logged in
        if (to.meta.redirectIfLoggedIn && isLoggedIn) {
            const userData = getUserData()
            next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
        }

        return next()
    }

    // check if current user is null, fecth it
    if(!store.getters['user/is_current_fetched']) {
        store.dispatch('user/fetchCurrent').then(beforeEachAction())
    }else {
        beforeEachAction()
    }
})

export default router
