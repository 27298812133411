const defaultProps = {
    disabled: {
        default: false,
        type: Boolean,
    },
    value: {},

    required: {
        default: false,
        type: Boolean
    },
    label: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        required: true,
    },
    rules: {
        type: String | Object,
        default: ''
    },
    vid: {
        type: String,
        required: true,
    },
    description: {
        default: undefined,
        type: String
    },
    validFeedback: {
        default: undefined,
        type: String
    },
    placeholder: {
        default: '',
        type: String
    },
    prepend: {
        default: undefined,
        type: String,
    },
    readonly: {
        default: false,
        type: Boolean,
    },
    plaintext: {
        default: false,
        type: Boolean,
    },
    debounce: {
        default: undefined,
        type: String | Number,
    },
    formatter: {
        default: undefined,
        type: Function
    },
    dir: {
        default: 'auto',
        type: String,
    },
    lazyFormatter: {
        type: Boolean
    }
}

export const DefaultInputProps = {
    ...defaultProps,
    ltr: {
        default: undefined,
        type: Boolean,
    },
    type: {
        type: String,
        default: 'text',
    },
    debounce: {
        default: undefined,
        type: String | Number,
    },
    formatter: {
        default: undefined,
        type: Function
    },
    lazyFormatter: {
        type: Boolean
    }
}

export const DefaultSelectProps = {
    ...defaultProps,
    options: {
        required: true,
        type: Array | Object
    },
    size: {
        type: String
    },
    textField: {
        type: String
    },
    valueField: {
        type: String
    },
    multiple: {
        type: Boolean
    },
}

export const DefaultInputFileProps = {
    ...defaultProps,
}

export const DefaultTagProps = {
    ...defaultProps,
    limit: {
        default: undefined,
        type: Number,
    },
    inputId: {
        default: undefined,
        type: String
    },
    tagValidator: {
        type: Function
    },
    separator: {
        type: Array | String
    },
    size: {
        type: String
    },
    tagVariant: {
        type: String
    },
    tagPills: {
        default: false,
        type: Boolean
    },

}

export const DefaultTextAreaProps = {
    ...defaultProps,
    size: {
        type: String
    },
    row: {
        type: String
    },
    maxRows: {
        type: String
    },
    debounce: {
        default: undefined,
        type: String | Number,
    },
    formatter: {
        default: undefined,
        type: Function
    },
    lazyFormatter: {
        type: Boolean
    }
}