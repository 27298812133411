export default [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/user/Users'),
        meta: {
            pageTitle: 'کاربران',
            breadcrumb: [
                {
                    text: 'کاربران',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/users/:id/edit',
        name: 'edit-user',
        component: () => import('@/views/user/EditUser'),
        meta: {
            pageTitle: 'ویرایش کاربر',
            breadcrumb: [
                {
                    text: 'کاربران',
                    to: '/users'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/users/create',
        name: 'create-user',
        component: () => import('@/views/user/CreateUser'),
        meta: {
            pageTitle: 'کاربر جدید',
            breadcrumb: [
                {
                    text: 'کاربران',
                    to: '/users'
                },
                {
                    text: 'جدید',
                    active: true,
                },
            ],
        },
    },
]