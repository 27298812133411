export default [
    {
        path: '/posts',
        name: 'posts',
        component: () => import('@/views/post/Posts'),
        meta: {
            pageTitle: 'نوشته',
            breadcrumb: [
                {
                    text: 'نوشته',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/posts/deleted',
        name: 'posts-deleted',
        component: () => import('@/views/post/DeletedPosts'),
        meta: {
            pageTitle: 'نوشته های حذف شده',
            breadcrumb: [
                {
                    text: 'نوشته',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/posts/:id/edit',
        name: 'edit-post',
        component: () => import('@/views/post/EditPost'),
        meta: {
            pageTitle: 'ویرایش نوشته',
            breadcrumb: [
                {
                    text: 'نوشته',
                    to: '/posts'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/posts/create',
        name: 'create-post',
        component: () => import('@/views/post/CreatePost'),
        meta: {
            pageTitle: 'نوشته جدید',
            breadcrumb: [
                {
                    text: 'نوشته',
                    to: '/posts'
                },
                {
                    text: 'جدید',
                    active: true,
                },
            ],
        },
    },
]