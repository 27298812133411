export default [
    {
        path: '/comments',
        name: 'comments',
        component: () => import('@/views/comment/Comments'),
        meta: {
            pageTitle: 'نظرات',
            breadcrumb: [
                {
                    text: 'نظرات',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/comments/:id/edit',
        name: 'edit-comment',
        component: () => import('@/views/comment/EditComment'),
        meta: {
            pageTitle: 'ویرایش نظر',
            breadcrumb: [
                {
                    text: 'نظرات',
                    to: '/comments'
                },
                {
                    text: 'ویرایش',
                    active: true,
                },
            ],
        },
    }
]