import * as rules from 'vee-validate/dist/rules'
import { localize, extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import fa from 'vee-validate/dist/locale/fa.json';
import en from 'vee-validate/dist/locale/en.json';
import Vue from "vue";
import { validatorPositive, validatorUrlValidator } from "@core/utils/validations/validators";

localize({
    fa,
    en
});

localize('fa')

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
extend('url', {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
})
extend('positive', {
    validate: validatorPositive,
    message: 'Please enter positive number!',
})
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
