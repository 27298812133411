import { actorService } from '@/libs/api'
import { groupByArrayOfObject } from '@/common/arrays'

const initialStates = {
    status: 'LOADING',
    actorsList: [],
    pagination: {
        per_page: 15,
        current_page: 1,
        last_page: 1,
    },
    params: {
        page: 1
    }
}

const mutations = {
    loading(state) {
        state.status = 'LOADING'
    },
    setActorsList(state, actors) {
        state.status = 'SET_LIST'
        state.actorsList = actors
    },
    setPagination(state, pagination) {
        state.status = 'SET_PAGINATION'
        state.pagination = Object.assign({}, state.pagination, pagination)
    },
    setParams(state, params) {
        state.status = 'SET_PARAMS'
        state.params = Object.assign({}, state.params, params)
    },
    createActor(state, actor) {
        state.status = 'CREATE'
        state.actorsList[actor.id] = actor
    },
    updateActor(state, actor) {
        state.status = 'UPDATE'
        state.actorsList[actor.id] = Object.assign({}, state.actorsList[actor.id], actor)
    },
    deleteActor(state, id) {
        state.status = 'DELETE_FROM_LIST'
        delete state.actorsList[id]
    },
    error(state) {
        state.status = 'ERROR'
    }
}

const getters = {
    status: state => state.status,
    params: state => state.params,
    actors: state => state.actorsList,
    pagination: state => state.pagination,
    actorById: state => id => state.actorsList[id],
    selectableActors: (state, getters) => {
        return Object.keys(getters.actors).map((id) => {
            return {
                value: getters.actors[id].id,
                label: getters.actors[id].name,
            }
        })
    }
}

const actions = {
    fetch({ commit, state }, params = {}) {
        params = Object.assign({}, state.params, params)

        commit('loading')

        return actorService.fetch(params).then(response => {
            let result = response.data
            const actors = groupByArrayOfObject(result.data, 'id')

            commit('setActorsList', actors)
            commit('setPagination', {
                per_page: result.per_page,
                current_page: result.current_page,
                last_page: result.last_page,
                total: result.total,
            })
            commit('setParams', {
                page: result.current_page,
            })
        })
    },

    create({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            actorService.create(params)
                .then(response => {
                    let result = response.data

                    commit('createActor', result.data)
                    resolve(response.data)
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    edit({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            actorService.edit(params, params.id)
                .then(() => {
                    commit('updateActor', params)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },

    delete({ commit }, id) {
        commit('loading')
        return new Promise((resolve, reject) => {
            actorService.delete(id)
                .then(() => {
                    commit('deleteActor', id)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}