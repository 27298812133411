import Axios from 'axios'
import { postService, userService } from '@/libs/api'
import { groupByArrayOfObject } from '@/common/arrays'

const initialStates = {
    status: 'LOADING',
    current: {},
    usersList: {},
    user: {},
    permissionsList: {},
    rolesList: {},
    pagination: {
        per_page: 15,
        current_page: 1,
        last_page: 1,
    },
    params: {
        page: 1
    },
}

const mutations = {
    loading(state) {
        state.status = 'LOADING'
    },
    setCurrentUser(state, current) {
        state.status = 'SET_CURRENT_USER'
        state.current = current
    },
    setUsersList(state, users) {
        state.status = 'SET_LIST'
        state.usersList = users
    },
    updateUser(state, user) {
        state.status = 'UPDATE'
        state.usersList[user.id] = Object.assign({}, state.usersList[user.id], user)
    },
    setPermissionsList(state, permissionsList) {
        state.status = 'SET_PERMISSIONS_LIST'
        state.permissionsList = permissionsList
    },
    setRolesList(state, rolesList) {
        state.status = 'SET_ROLES_LIST'
        state.rolesList = rolesList
    },
    findUser(state, user) {
        state.status = 'FIND'
        state.user = user
        state.usersList[user.id] = user
    },
    setPagination(state, pagination) {
        state.status = 'SET_PAGINATION'
        state.pagination = Object.assign({}, state.pagination, pagination)
    },
    setParams(state, params) {
        state.status = 'SET_PARAMS'
        state.params = Object.assign({}, state.params, params)
    }
}

const getters = {
    current: ({ current }) => current,
    status: ({ status }) => status,
    is_current_fetched: ({ current }) => {
        return Object.keys(current).length > 0
    },
    users: state => state.usersList,
    userById: state => id => state.usersList[id],
    params: state => state.params,
    pagination: state => state.pagination,
    user: state => state.user,
    permissionsList: state => state.permissionsList,
    rolesList: state => state.rolesList,
    selectableRoles: (state, getters) => {
        return Object.keys(getters.rolesList).map((id) => {
            return {
                value: getters.rolesList[id].id,
                text: getters.rolesList[id].name,
            }
        })
    }

}

const actions = {
    fetchCurrent({ commit, rootGetters }) {
        commit('loading')
        return new Promise((resolve, reject) => {
            userService.current().then(response => {
                commit('setCurrentUser', response.data.data)

                // update ability
                const ability = rootGetters.ability
                ability.update(response.data.data.rules)
                // console.log(ability)
                resolve()
            }).catch(() => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('userData')
                delete Axios.defaults.headers.common['Authorization']
                reject()
            })
        })
    },
    fetch({ commit, state }, params = {}) {
        params = Object.assign({}, state.params, params)

        commit('loading')
        return userService.fetch(params).then(response => {
            let result = response.data
            const users = groupByArrayOfObject(result.data, 'id')

            commit('setUsersList', users)
            commit('setPagination', {
                per_page: result.per_page,
                current_page: result.current_page,
                last_page: result.last_page,
                total: result.total
            })
            commit('setParams', {
                page: result.current_page,
            })
        })
    },
    edit({ commit }, params = {}) {
        commit('loading')

        return new Promise((resolve, reject) => {
            userService.update(params, params.id)
                .then(() => {
                    commit('updateUser', params)
                    resolve()
                })
                .catch(error => {
                    commit('error')
                    reject(error.response.data)
                })
        })
    },
    find({ commit }, id) {
        commit('loading')
        return userService.find(id).then(response => {
                const user = response.data.data

                commit('findUser', user)
            }
        )
    },
    fetchPermissionsList({ commit }) {
        commit('loading')
        return userService.permissionsList().then(
            response => {
                let result = response.data
                let permissionsList = result.data
                commit('setPermissionsList', permissionsList)
            }
        )
    },
    fetchRolesList({ commit }) {
        commit('loading')
        return userService.rolesList().then(
            response => {
                let result = response.data
                let rolesList = result.data
                commit('setRolesList', rolesList)
            }
        )
    }
}

export default {
    namespaced: true,
    state: initialStates,
    mutations: mutations,
    getters: getters,
    actions: actions,
}