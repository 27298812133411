import Vue from 'vue'
import Vuex from 'vuex'
import ability from "@/libs/acl/ability";

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import { actor, auth, category, comment, filter, genre, post, redirect, user } from "@/store/modules"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        actor,
        category,
        comment,
        auth,
        genre,
        post,
        redirect,
        user,
        filter
    },
    strict: process.env.DEV,
    getters: {
        ability() {
            return ability
        }
    }
})
